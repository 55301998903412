<template>
    <div class="about-timeline">
        <div class="about-timeline-inner">

            <div class="timeline-event">
                <div class="timeline-dropdown"
                    data-aos="fade-up"
                    data-aos-duration="1800">
                    <div>
                        <div class="timeline-title">National Technical University of Athens</div>
                        <div class="timeline-subtitle">Bachelor of Science | Sep 2011 - Sep 2012</div>
                    </div>
                    <div class="btn-timeline-drop">
                        <img  :src="require(`@/assets/Images/Icons/${arrowCurrent}`)" 
                              @click="changeArrowImg"
                              alt="arrow-down">
                    </div>
                </div>

                <div class="timeline-description">
                    <p class="about-thick">Bachelor studies at the School of Chemical Engineering</p>
                </div>
            </div>

            <div class="timeline-event">
                <div class="timeline-dropdown"
                    data-aos="fade-up"
                    data-aos-duration="1800">
                    <div>
                        <div class="timeline-title">„POLITEHNICA” University of Bucharest</div>
                        <div class="timeline-subtitle">Bachelor of Science | Sep 2012 - Jul 2015</div>
                    </div>
                    <div class="btn-timeline-drop">
                        <img  :src="require(`@/assets/Images/Icons/${arrowCurrent}`)" 
                              @click="changeArrowImg"
                              alt="arrow-down">
                    </div>
                </div>

                <div class="timeline-description">
                    <p class="about-thick">BS Oxyde Materials and Nanomaterials</p>
                    <ul class="timeline-list">
                        <li>Conducted an undergraduate thesis on the <span class="about-thick">valorisation of ash</span> and <span class="about-thick">vitreous waste for the fabrication of
                            ceramic mesoporous materials</span> with structural uses</li>
                        <li><span class="about-important">1st place</span> award in the <span class="about-thick">Chemistry and Chemical
                            Engineering conference "Students for Students"</span></li>
                    </ul>
                </div>
            </div>

            <div class="timeline-event">
                <div class="timeline-dropdown"
                    data-aos="fade-up"
                    data-aos-duration="1800">
                    <div>
                        <div class="timeline-title">„POLITEHNICA” University of Bucharest</div>
                        <div class="timeline-subtitle">Master of Science | Sep 2015 -Jul 2017</div>
                    </div>
                    <div class="btn-timeline-drop">
                        <img  :src="require(`@/assets/Images/Icons/${arrowCurrent}`)" 
                              @click="changeArrowImg"
                              alt="arrow-down">
                    </div>
                </div>

                <div class="timeline-description">
                    <p class="about-thick">Master in Advanced Composite Materials</p>
                    <p>Investigated Ni-B and bruchite depositions with applications in the aerospace industry</p>
                </div>
            </div>

            <div class="timeline-event">
                <div class="timeline-dropdown"
                    data-aos="fade-up"
                    data-aos-duration="1800">
                    <div>
                        <div class="timeline-title">Production engineer intern</div>
                        <div class="timeline-subtitle">Industria Aeronautică Română | Jun 2014 - Aug 2014</div>
                    </div>
                    <div class="btn-timeline-drop">
                        <img  :src="require(`@/assets/Images/Icons/${arrowCurrent}`)" 
                              @click="changeArrowImg"
                              alt="arrow-down">
                    </div>
                </div>

                <div class="timeline-description">
                    <p><span class="about-thick">Internship subject:</span> Possibilities for improvement of the
                        mechanical properties of aluminum alloys used in the aerospace industry</p>
                </div>
            </div>

            <div class="timeline-event">
                <div class="timeline-dropdown"
                    data-aos="fade-up"
                    data-aos-duration="1800">
                    <div>
                        <div class="timeline-title">Chemistry dept. - Production engineer</div>
                        <div class="timeline-subtitle">Stimpex | May 2015 - May 2016</div>
                    </div>
                    <div class="btn-timeline-drop">
                        <img  :src="require(`@/assets/Images/Icons/${arrowCurrent}`)" 
                              @click="changeArrowImg"
                              alt="arrow-down">
                    </div>
                </div>

                <div class="timeline-description">
                    <p><span class="about-important-title">Outline:</span> Production, R&D project management and support activities</p>
                    <br>
                    <p class="about-important-title">Key responsibilities:</p>
                    <ul class="timeline-list">
                        <li>Chemical and composite products management</li>
                        <li>Manufacturing process coordination</li>
                        <li>Technical and technological specifications specialist</li>
                        <li>Chemical laboratory responsible</li>
                        <li>Internal quality control</li>
                    </ul>
                    <br>
                    <p class="about-important-title">Major achievements:</p>
                    <ul class="timeline-list">
                        <li>Successfully performed the <span class="about-thick">design activities</span> and <span class="about-thick">supervised the production activities of 120 composite</span>
                            armors for the optical blocks of <span class="about-important">Anubis© turrets</span></li>
                        <li>
                            <span class="about-important">Project lead for the REXKIT</span> (explosive substances identification kit) manufacturing process;
                            identification of the pain points of the processes and possible workarounds
                        </li>
                    </ul>
                </div>

            </div>

            <div class="timeline-event">
                <div class="timeline-dropdown"
                    data-aos="fade-up"
                    data-aos-duration="1800">
                    <div>
                        <div class="timeline-title">M&P dept. - M&P engineer</div>
                        <div class="timeline-subtitle">EXPLEO | May 2016 - Feb 2019</div>
                    </div>
                    <div class="btn-timeline-drop">
                        <img  :src="require(`@/assets/Images/Icons/${arrowCurrent}`)" 
                              @click="changeArrowImg"
                              alt="arrow-down">
                    </div>
                </div>

                <div class="timeline-description">
                    <p class="about-important-title">Outline:</p>
                    <ul class="timeline-list">
                        <li><span class="about-thick">Qualification activities for the AIRBUS</span> metallic materials dept. (ESCMU)</li>
                        <li><span class="about-thick">Standardization activities for the AIRBUS</span> standardization dept. (ESCMMS)</li>
                    </ul>
                    <br>
                    <p class="about-important-title">Key responsibilities:</p>
                    <ul class="timeline-list">
                        <li><span class="about-thick">Qualification Test Reports</span> (QTRs)</li>
                        <li><span class="about-thick">Qualification Test Plans</span> (QTPs)</li>
                        <li>Process management and transversal support
                            activities for the standardization department</li>
                        <li><span class="about-thick">Team leader of Assystem Technologies Romania</span>
                            standardization team</li>
                        <li><span class="about-thick">Internal quality control</span> responsible for the
                            qualification activities</li>
                    </ul>
                    <br>
                    <p class="about-important-title">Major achievements:</p>
                    <ul class="timeline-list">
                        <li><span class="about-thick">Increased the quality index</span> of the qualification
                            activities <span class="about-important">from a minimum of 25% to a minimum of 95% in 1 year</span></li>

                        <li>Comprehended the <span class="about-thick">“big picture” of the process</span>,
                            offered high quality services and added real value to
                            the project by coming up with improvement actions</li>

                        <li><span class="about-thick">Identified</span> the need for an <span class="about-important">internal, working
                            methodology</span> and <span class="about-thick">put one into place</span></li>

                        <li>Quickly adapted to the vocabulary particularities of
                            the various departments and contributed to the
                            enhancement of their collaboration</li>
                    </ul>
                </div>

            </div>

            <div class="timeline-event">
                <div class="timeline-dropdown"
                    data-aos="fade-up"
                    data-aos-duration="1800">
                    <div>
                        <div class="timeline-title">M&P dept. - Metallurgist engineer</div>
                        <div class="timeline-subtitle">GKN Fokker Engineering Romania | Feb 2019 - present</div>
                    </div>
                    <div class="btn-timeline-drop">
                        <img  :src="require(`@/assets/Images/Icons/${arrowCurrent}`)" 
                              @click="changeArrowImg"
                              alt="arrow-down">
                    </div>
                </div>

                <div class="timeline-description">
                    <p><span class="about-important-title">Outline:</span> Production support, non-conformity handling and specification management </p>
                    <br>
                    <p class="about-important-title">Key responsibilities:</p>
                    <ul class="timeline-list">
                        <li>Qualification activities for <span class="about-thick">metal-related</span> and <span class="about-thick">surface treatment-related processes</span></li>
                        <li>Non-conformity resolution</li>
                        <li>Project leading</li>
                        <li><span class="about-thick">Redaction of functional specifications/ standards</span> combining the specific needs of the Materials and
                            Processes, Engineering Manufacturing and Quality departments</li>
                        <li><span class="about-thick">Handbook</span> (specification and standard) <span class="about-thick">administration activities</span></li>
                    </ul>
                    <br>
                    <p class="about-important-title">Major achievements:</p>
                    <ul class="timeline-list">
                        <li><span class="about-important">Project lead</span> for the metallurgical investigation of the
                            <span class="about-thick">burn-in spots resulted from electric discharges in the anodization baths</span> on <span class="about-important">7175 sheet specimens</span></li>

                        <li><span class="about-important">Lead M&P engineer</span> in the investigation regarding the
                            possibility and metallurgical impact of <span class="about-thick">polishing to high-gloss finish anodized 2024 and 7075 parts</span></li>

                        <li><span class="about-important">Co-lead M&P engineer</span> in the investigation regarding
                            the impact of a decreased solution heat treatment on <span class="about-thick">7075 T73 extrusions</span></li>

                        <li><span class="about-important">Resolution of several high-value non-conformities</span>
                            <span class="about-thick">leading to cost reductions</span>, sometimes several tens of
                            thousand euros worth</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/About/about.css';
import '../../assets/CSS/About/about-timeline.css';


import { timelineDropdown } from '../../assets/JS/Timeline-Drop.js';


export default {
    data(){
        return{
            arrowCurrent: "arrow-down.png",
            arrowDown: "arrow-down.png",
            arrowUp: "arrow-up.png"
        };
    },
    mounted(){
        timelineDropdown();
    },
    methods:{
        changeArrowImg(){
            if(this.arrowCurrent === this.arrowDown)
                this.arrowCurrent = this.arrowUp;
            else
                this.arrowCurrent = this.arrowDown;
        }
    }
}
</script>