<template>
    <home-header></home-header>
    <cards-glass></cards-glass>
    <cards-border></cards-border>
    <home-pie-chart></home-pie-chart>
</template>

<script>
import HomeHeader from '../components/Home/HomeHeader.vue';
import HomePieChart from '../components/Home/HomePieChart.vue';

export default {
    components: {
        'home-header': HomeHeader,
        'home-pie-chart': HomePieChart
    }
}
</script>