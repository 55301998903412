<template>
<!-- ABOUT SKILLS -->
    <div class="container about-skills">
        <div class="about-skills-inner">
            <!-- CORE SKILLS -->
            <div class="about-core-skills">
                <div class="about-skill-title">Core skills</div>
                <div class="about-core-skills-inner">
                    <div class="about-core-skills-inner-div">
                        <p><span class="about-important">1. </span><span class="about-thick">Profound knowledge of material–processing–properties</span>
                            correlation of metallic and non-metallic (ceramic and composite) materials</p>
                        
                        <p><span class="about-important">2. </span><span class="about-thick">Hands-on experience in material, mechanical and
                            chemistry laboratories</span>, working for both R&D and production projects</p>
                        
                        <p><span class="about-important">3. </span><span class="about-thick">Keen eye, critical mind and creativity</span> required for the
                            handling and resolution of production non-conformances</p>
                    </div>    
                    <div class="about-core-skills-inner-div">
                        <p><span class="about-important">4. </span><span class="about-thick">Firm grasp</span> on the way <span class="about-thick">enterprise specifications and
                            international aerospace standards</span> function and on the correlations among them</p>
                        
                        <p><span class="about-important">5. </span><span class="about-thick">Efficient communication skills</span> for fruitful collaborations
                            with the clients and all possible stakeholders, having as an aim the <span class="about-thick">continuous development of the processes and final products</span></p>
                    </div>
                </div>
            </div>

            <!-- CORE SKILLS -->
            <div class="about-lang-skills">
                <div class="about-skill-title">Language skills</div>
                <div class="about-lang-skill-inner">
                    <div class="about-lang-skill-inner-div">
                        <div>
                            <span>Greek</span>
                            <div class="about-language-pie about-langue-pie-pro">C2</div>
                        </div>
                        <div>
                            <span>Romanian</span>
                            <div class="about-language-pie about-langue-pie-pro">C2</div>
                        </div>
                        <div>
                            <span>English</span>
                            <div class="about-language-pie about-langue-pie-pro">C2</div>
                        </div>
                    </div>
                    <div class="about-lang-skill-inner-div">
                        <div>
                            French
                            <div class="about-language-pie about-language-pie-beginner">A2</div>
                        </div>
                        <div>
                            Dutch
                            <div class="about-language-pie about-language-pie-beginner">A2</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- SECONDARY SKILLS -->
            <div class="about-secondary-skills">
                <div class="about-skill-title">Secondary skills</div>
                <ul class="timeline-list">
                    <li>Outcome-driven and stress-proof approach</li>
                    <li>Impeccable interpersonal skills</li>
                    <li>Meticulosity</li>
                    <li>Operational efficiency</li>
                    <li>Microsoft Office pack proficiency</li>
                </ul>
            </div>
            

            <!-- DIGITAL SKILLS -->
            <div class="about-digital-skills">
                <div class="about-skill-title">Digital skills</div>
                <ul class="timeline-list">
                    <li>Microsoft office pack</li>
                    <li>Google workspace</li>
                    <li>Xmetal .xml editor</li>
                </ul>
            </div>
            
            <!-- RELOCATION -->
            <div class="about-relocation">
                <div class="about-skill-subtitle">Driving license</div>
                <p style="margin-bottom: 16px;">B category</p>
                <div class="about-skill-subtitle">Relocation</div>
                <p>Available for relocation within the EU</p>
            </div>
            
        </div>
    </div>
</template>

<script>
import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/About/about.css';

export default {};
</script>