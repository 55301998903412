<template>
  <the-navigation></the-navigation>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import TheNavigation from './components/Navigation/TheNavigation.vue';

export default{
  components:{
    "the-navigation": TheNavigation
  }
}
</script>
