<template>
    <div id="the-navigation-bar">
        <div class="nav-bar">
            <div class="nav-bar-logo">
                <router-link to="/">
                    <img src="../../assets/Images/Logos/logo2.png" alt="logo" class="logo-sm" id="nav-logo-sm">
                </router-link>
                <img src="../../assets/Images/Icons/menu-blue.png" alt="menu" class="nav-icon-responsive" id="nav-menu">
                <img src="../../assets/Images/Icons/close-blue.png" alt="close" class="nav-icon-responsive" id="nav-close">
            </div>
            <div class="nav-bar-links">
                <div class="nav-bar-link">
                    <router-link to="/">Home</router-link>
                </div>
            <div class="nav-bar-link">
                    <router-link to="/about">About</router-link>
                </div>
                <div class="nav-bar-link">
                    <router-link to="/contact">Contact</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/General/navigation-bar.css';
import '../../assets/CSS/General/navigation-bar-responsive.css';

import { modifyNavigationResponsive } from '../../assets/JS/NavigationResponsive.js';
import { modifyNavigationOnScroll } from '../../assets/JS/NavigationScroll.js';

export default {
    data(){
        return{
            isScrolled: false,
            windowTop: 0
        };
    },
    methods:{
        changeScrollState(){
            this.isScrolled = !this.isScrolled;
        }
    },
    mounted(){
        modifyNavigationResponsive(),
        modifyNavigationOnScroll();
    }
}
</script>