export function timelineDropdown(){
    const btnsTimelineDrop = document.querySelectorAll(".btn-timeline-drop img");
    const timelineDescriptions = document.querySelectorAll(".timeline-description");

    for(let i = 0; i < timelineDescriptions.length; i++){
        timelineDescriptions[i].style.display = "none";
        btnsTimelineDrop[i].addEventListener("click", () => {
            if(timelineDescriptions[i].style.display === "none"){
                timelineDescriptions[i].style.display = "block";
            } else{
                timelineDescriptions[i].style.display = "none";
            }
        });
    }
}