export function modifyNavigationOnScroll(){
    const myNavigationBar = document.querySelector('#the-navigation-bar');
    const myNavigationBarInner = document.querySelector('.nav-bar');
    const myNavLogo = document.querySelector('#nav-logo-sm');

    // Responsive icons
    const myNavMenu = document.querySelector("#nav-menu");
    const myNavClose = document.querySelector("#nav-close");
                  
    // Toggle between the scrolled and normal elements
    // --> the scrolled elements are smaller
    // --> the normal elements are bigger
    function changeNavState(action){
        if(action === "add"){
            myNavigationBarInner.classList.add("nav-bar-scrolled");
            myNavLogo.classList.add("logo-sm-scrolled");
            myNavMenu.classList.add('nav-icon-responsive-scrolled');
            myNavClose.classList.add('nav-icon-responsive-scrolled');
        } else {
            myNavigationBarInner.classList.remove("nav-bar-scrolled");
            myNavLogo.classList.remove("logo-sm-scrolled");
            myNavMenu.classList.remove('nav-icon-responsive-scrolled');
            myNavClose.classList.remove('nav-icon-responsive-scrolled');
        }
    }

    window.onscroll = () => {
        "use strict";
        const navHeight = myNavigationBar.offsetHeight;

        if(document.body.scrollTop >= navHeight || document.documentElement.scrollTop >= navHeight ){
            changeNavState("add");
        } else {
            changeNavState("remove");
        }
    }
}
