<template>

    <div class="container container-tent-bg">
        <div class="container-title container-title-blue">Why would you choose a consultant M&P engineer?</div>
        <div class="cards-pictogram ">
            <div class="card-pictogram card-border" 
                v-for="card in cards" 
                :key="card.cardId"
                data-aos="fade-up"
                :data-aos-duration="card.fadeDuration"
            >
                <h1 class="card-title card-title-blue">{{ card.cardTitle }}</h1>
                <p class="card-description" v-html="card.cardDescription"></p>
            </div>
        </div>
    </div>

</template>

<script>
import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/General/cards.css';

export default{
    data(){
        return{
            cards: [
                {
                    cardId: 1,
                    cardTitle: "Risk mitigation",
                    cardDescription: "Recently confirmed project with short deadline? Resources needed for a project whose perspectives are uncertain? <br><br> Then a consultant is the perfect choice for your project! Hiring and collaboration interruption are a matter of hours. Just like that.",
                    fadeDuration: 1500
                },
                {
                    cardId: 2,
                    cardTitle: "Cost reduction",
                    cardDescription: "No more benefit-related costs and no need for expensive accounting services. No need for onboarding trainings and welcome parties. A consultant is quick like a ninja. Quick in, quick out!",
                    fadeDuration: 1800
                },
                {
                    cardId: 3,
                    cardTitle: "High quality of work",
                    cardDescription: "A consultant is driven and evaluated by his/her on-time and on-quality deliveries. Hence, a quality guarantee is automatically imbedded in any consultant delivery.",
                    fadeDuration: 2100
                },
                {
                    cardId: 4,
                    cardTitle: "Fast task delivery",
                    cardDescription: "A consultant will always try to finish his/her contract deliverables as soon as possible respecting the highest quality standards so that he can move forward to the next contract.<br><br> The consultant's ambition to develop and provide a living is your guarantee that your project deliverables will be finished on time and with impeccable quality.",
                    fadeDuration: 2400
                },
            ]        
        };
    }
};
</script>
