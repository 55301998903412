<template>
    <div class="container home-header">
        <div></div>
        <h3>If you wouldn’t fly in a paper airplane, <br>you probably need an M&P guy.</h3>
        <div>
            <h1>Alex <br> Micu</h1>
            <h2>THE M&P GUY</h2>
        </div>
        <!-- <div class="btn-scroll-down">
            <div class="btn-scroll-down-ball"></div>
        </div> -->
    </div>
</template>

<script>
import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/General/button-scroll.css';

export default {};
</script>

<style scoped>
/* HOME HEADER */
.home-header{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    height: calc(100vh - var(--L-padding));
    text-align: center;
    background-image: url("../../assets/Images/Backgrounds/RocketBGTransparent.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--color-primary);    
    text-align: center;
}
.home-header::after{
    content:".";
    z-index: 10;
    position: absolute;
    background: linear-gradient(to bottom, var(--color-secondary), var(--color-light));
    height: 100%;
    widows: 100%;
    top:0;
    left:0;
}

.home-header h1{
    font-size: calc(var(--L-title) * 1.5);
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
}
.home-header h2{
    font-size: var(--M-title);
    letter-spacing: 3px;
    font-family: 'Coming Soon', cursive;
}

.home-header h3{
    font-family: 'Coming Soon', cursive;
}
</style>