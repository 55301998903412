export function modifyNavigationResponsive() {
    const btnNavMenu = document.querySelector('#nav-menu');
    const btnNavClose = document.querySelector('#nav-close');
    const myNavigationBarInnerND = document.querySelector('.nav-bar');
    const myNavLinks = document.querySelector('.nav-bar-links');
    let isNotScrolled = true;

    // Nav links
    const myNavLinksHref = document.querySelectorAll(".nav-bar-link a");

    // Menu btn --> display links, make the menu = height of the page
    btnNavMenu.addEventListener("click", () => {
        btnNavClose.style.display = "block";
        btnNavMenu.style.display = "none";

        if(!myNavigationBarInnerND.classList.contains("nav-bar-scrolled")){
            isNotScrolled = false;
            myNavigationBarInnerND.classList.add("nav-bar-scrolled");
        } else{
            isNotScrolled = true;
        }

        myNavigationBarInnerND.style.height = "100vh";
        myNavLinks.style.display = "block";
    });

    // Close btn --> make the menu responsive again
    btnNavClose.addEventListener("click", () => {
        btnNavMenu.style.display = "block";
        btnNavClose.style.display = "none";

        if(!isNotScrolled){
            myNavigationBarInnerND.classList.remove("nav-bar-scrolled");
        }

        myNavigationBarInnerND.style.height = "auto";
        myNavLinks.style.display = "none";
    });

    // Add the eventListener only if the website is in responsive mode

    if( window.innerWidth < 576){
        for(let i = 0; i < myNavLinksHref.length; i++){
            myNavLinksHref[i].addEventListener("click", () => {
                btnNavMenu.style.display = "block";
                btnNavClose.style.display = "none";
                myNavigationBarInnerND.style.height = "auto";
                myNavLinks.style.display = "none";
            });
        }
    }
}
