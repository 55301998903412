<template>
    <!-- ABOUT HEADER -->
    <div class="container container-nav about-outer">
        <div class="about-header">
            <div class="about-header-left">
                <div style="margin-bottom: var(--M-padding);">
                    <div class="container-L-title">Alexandru Micu</div>
                    <div class="container-subtitle">Materials engineer</div>
                </div>
                <div class="container-subtitle" 
                    style="text-transform:none">Personal mission:</div>
                <p>
                    To use my knowledge and experience in materials engineering in order to
                optimize processes that deliver high-quality products.</p>
            </div>
            <div class="about-header-right">
                <img src="../assets/Images/Profile/About-profile-round.jpg" alt="about">
            </div>
        </div>
    </div>
    <about-timeline></about-timeline>
    <about-skills></about-skills>    
</template>

<script>
import '../assets/CSS/style.css';
import AboutSkills from '../components/About/AboutSkills.vue';
import AboutTimeline from '../components/About/AboutTimeline.vue';

export default {
    components:{
        "about-timeline": AboutTimeline,
        "about-skills": AboutSkills
    }
}
</script>