// AOS - for animations
import AOS from 'aos'
import 'aos/dist/aos.css'

import { createApp } from 'vue';
import router from './router/index.js'
import App from './App.vue'

import CardsGlass from './components/UI/CardsGlass.vue';
import CardsBorder from './components/UI/CardsBorder.vue';

const app = createApp(App);

app.component('cards-glass', CardsGlass);
app.component('cards-border', CardsBorder);

app.use(AOS.init());
app.use(router);
app.mount('#app');