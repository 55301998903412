<template>

    <div class="container container-wave-bg">
        <div class="cards-pictogram">

            <div class="card-pictogram card-glass" 
                v-for="card in cards" 
                :key="card.cardId"
                data-aos="fade-up"
                :data-aos-duration="card.fadeDuration"
            >
                <h1 class="card-title">{{ card.cardTitle }}</h1>
                <ul class="card-options">
                    <div class="card-option" 
                        v-for="option in card.cardOptions" 
                        :key="option"
                    >
                        <img src="../../assets/Images/play.png" alt="check">
                        <li>{{ option }}</li>
                    </div>
                </ul>
            </div>
            
        </div>
    </div>

</template>

<script>
import 'aos/dist/aos.css';
import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/General/cards.css';

export default{
    data(){
        return{
            cards: [
                {
                    cardId: 1,
                    cardTitle: "Metals, technology, surface treatment, expertise and ceramic materials engineering",
                    cardOptions: [
                        "Consultancy",
                        "Production support",
                        "Non-conformance support"
                    ],
                    fadeDuration: 1500
                },
                {
                    cardId: 2,
                    cardTitle: "Report and specification writing",
                    cardOptions: [
                        "Qualification Test Plans",
                        "Qualification Test Reports",
                        "Investigation Reports",
                        "Specification authoring"
                    ],
                    fadeDuration: 1800
                },
                {
                    cardId: 3,
                    cardTitle: "Project management",
                    cardOptions: [
                        "Resource coordination",
                        "KPI monitoring",
                        "Non-conformance support",
                        "Specification authoring"
                    ],
                    fadeDuration: 2100
                },
                {
                    cardId: 4,
                    cardTitle: "Technical interview assistance",
                    cardOptions: [
                        "Interview question list",
                        "Conducting techincal interviews",
                    ],
                    fadeDuration: 2400
                },
            ]        
        };
    }
};
</script>
