<template>
        <div class="container container-pie-chart">
            <div>
                <p class="big-pie-title">MY CURRENT</p>
                <p class="big-pie-title big-pie-title-workload">WORKLOAD</p>
            </div>
            <div class="pie pie-animate no-round big-pie-title" style="--p:80;--c:var(--color-light);">80%</div>
        </div>
</template>

<script>

import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/pie-chart.css';

export default{};

</script>

<style scoped>
.container-pie-chart div{ 
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
    color: var(--color-light);
}
.big-pie-title{ font-size: var(--L-title); }
.big-pie-title-workload{ letter-spacing: 5px;}

@media screen and (max-width: 1200px){
    .container-pie-chart{ flex-direction: column;}
    .container-pie-chart div:nth-child(2){
        padding: var(--M-padding) 0;
    }
}

@media screen and (max-width: 333px){
    .big-pie-title{ font-size: var(--M-title); }
}
</style>


