<template>
    <contact-card></contact-card>
</template>

<script>
import ContactCard from '../components/Contact/ContactCard.vue';

export default {
    components:{
        "contact-card": ContactCard
    }
}
</script>
