<template>
    <div class="container-contact">
        <div class="contact-infos">
            <div class="container-title">Let's get in touch!</div>
            <div class="contact-details">
                <div class="contact-info">
                    <img src="../../assets/Images/Icons/phone.png" alt="icon-email">
                    <p>1st: +40 741 321 201</p>
                </div>
                <div class="contact-info">
                    <img src="../../assets/Images/Icons/phone.png" alt="icon-email">
                    <p>2nd: To be done</p>
                </div>
                <div class="contact-info">
                    <img src="../../assets/Images/Icons/email.png" alt="icon-email">
                    <p>mp@alexandrumicu.com</p>
                </div>
                <div class="contact-info">
                    <img src="../../assets/Images/Icons/linked.png" alt="icon-email">
                    <p><a href="https://www.linkedin.com/in/alexandru-micu" target="_blank">LinkendIn</a></p>
                </div>
            </div>
            <p class="contact-description">
                Whether you would like to chat about the new tendencies in metal forming techniques or about how life as a new father seems to me, whether you would like to discuss details about a potential collaboration in frame of your new project or details about why I consider nuclear energy to be a good “placeholder” in today’s green energy sector, whether you would like to grab a coffee over a virtual Google meet or grab a beer over a physical meeting, please feel free to use my contact details.
                <br><br>
                Stay amazing!<br>
                Alex, the M&P guy
            </p>
        </div>
        <!-- <img src="/Images/Triangle.svg" alt="triangles" id="contact-triangles"> -->
        <div id="contact-profile-img">
            <img src="../../assets/Images/Profile/172OZY_6277.jpg" alt="profile_img">
        </div>
    </div>
</template>

<script>

import '../../assets/CSS/reset-sheet.css';
import '../../assets/CSS/style.css';
import '../../assets/CSS/Contact/contact.css';

export default{
}

</script>
